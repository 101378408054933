<template>
    <div class="chartContainer text-center">
        <h2 class="h3">Humidity</h2>
        <Doughnut :options="chartOptions" :data="chartData()" />
        <h3 class="h4">{{ value }}%</h3>
    </div>
</template>
  
<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'HumidityChart',
    props: ['value'],
    components: { Doughnut },
    computed: {
        dataSetData() {
            let valueArc = this.value;
            let remainderArc = this.range - valueArc;
            return [(valueArc/this.range),(remainderArc/this.range)]
        },
        arcColor() {
            if (this.value < 30) {
                return '#d2b48c'; // Light Brown for very dry
            } else if (this.value >= 30 && this.value < 50) {
                return '#f5f5dc'; // Beige for moderately dry
            } else if (this.value >= 50 && this.value < 70) {
                return '#198754'; // Light Green for ideal outdoor range
            } else if (this.value >= 70 && this.value < 80) {
                return '#ffc107'; // Yellow for moderately humid
            } else if (this.value >= 80 && this.value < 90) {
                return '#fd7e14'; // Orange for very humid
            } else if (this.value >= 90) {
                return '#dc3545'; // Dark Red for extremely humid
            }
            return '#98fb98';
        }
    },
    methods: {
        chartData() {
            return {
                labels: ['Humidity (%)'],
                datasets: [{
                    data: this.dataSetData,
                    label: 'Humidity (%)',
                    backgroundColor: [this.arcColor, "#2b3035"]
                }]
            }
        }
    },
    data() {
        return {
            range: 100,
            chartOptions: {
                rotation: 270,
                cutout: '60%',
                circumference: 180,
                responsive: true,
                aspectRatio:2,
                layout: {
                    autoPadding: false,
                    padding:0
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },

            },
        }
    }
}
</script>