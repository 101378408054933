<template>
    <div class="chartContainer text-center">
        <h2 class="h3">Solar</h2>
        <Doughnut :options="chartOptions" :data="chartData()" />
        <h3 class="h4">{{ value }} Lux</h3>
    </div>
</template>
  
<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'SolarChart',
    props: ['value'],
    components: { Doughnut },
    computed: {
        dataSetData() {
            let valueArc = this.value;
            let remainderArc = this.range - valueArc;
            return [(valueArc/this.range),(remainderArc/this.range)]
        },
        arcColor() {
            if (this.value >= 0 && this.value <= 1000) {
                return '#343a40'; // Dark Blue for very dark
            } else if (this.value > 1000 && this.value <= 10000) {
                return '#0dcaf0'; // Light Blue for low light
            } else if (this.value > 10000 && this.value <= 50000) {
                return '#198754'; // Green for moderate light
            } else if (this.value > 50000 && this.value <= 100000) {
                return '#ffc107'; // Yellow for bright light
            } else if (this.value > 100000) {
                // For a gradient effect from orange to red, you would typically handle this via CSS or a visual effect rather than a single hex code.
                // As a simplification here, we choose a midpoint or adjust based on context.
                return this.value <= 175000 ? '#fd7e14' : '#dc3545'; // Orange to Red for very bright light
            }
            return '#343a40'; // Default color for any out-of-range values
        }
    },
    methods: {
        chartData() {
            return {
                labels: ['Solar intensity'],
                datasets: [{
                    data: this.dataSetData,
                    label: 'Solar intensity',
                    backgroundColor: [this.arcColor, "#2b3035"]
                }]
            }
        }
    },
    data() {
        return {
            range: 250000,
            chartOptions: {
                cutout: '60%',
                rotation: 270,
                circumference: 180,
                responsive: true,
                aspectRatio:2,
                layout: {
                    autoPadding: false,
                    padding:0
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },

            },
        }
    }
}
</script>