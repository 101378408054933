<template>
    <NavBar />
    <router-view />
</template>

<script>
// @ is an alias to /src
import NavBar from '@/NavBar.vue'

export default {
    components: {
        NavBar
    }
}
</script>


<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin:5rem 0 0 0 !important;
    }
    .chartContainer{
        position:relative;
        margin-bottom:15px;
    }
    .chartContainer h3 {
        position:absolute;
        bottom:5%;
        left:50%;
        transform:translateX(-50%);
    }
    .chartContainer h2 {
        width:100%;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
    }
    @media(max-width:992px) {
        .chartContainer h3 {
            position:absolute;
            bottom:2%;
            left:50%;
            transform:translateX(-50%);
            font-size:1rem;
        }
    }
</style>
