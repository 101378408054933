<template>
    <div class="chartContainer text-center">
        <h2 class="h3">Temperature</h2>
        <Doughnut :options="chartOptions" :data="chartData()" />
        <h3 class="h4">{{ value }}&deg;C</h3>
    </div>
</template>
  
<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'TemperatureChart',
    props: ['value'],
    components: { Doughnut },
    computed: {
        dataSetData() {
            let valueArc = this.value + 10;
            let remainderArc = this.range - valueArc;
            return [(valueArc/this.range),(remainderArc/this.range)]
        },
        arcColor() {
            if (this.value < -4) {
                return '#0d6efd'; // Dark Blue for extreme cold
            } else if (this.value >= -4 && this.value < 5) {
                return '#0dcaf0'; // Light Blue for cold
            } else if (this.value >= 5 && this.value < 15) {
                return '#198754'; // Green for cool
            } else if (this.value >= 15 && this.value < 22) {
                return '#f0dea5'; // Light Yellow for mild
            } else if (this.value >= 22 && this.value < 28) {
                return '#ffc107'; // Yellow for warm
            } else if (this.value >= 28 && this.value < 32) {
                return '#fd7e14'; // Orange for the start of hot temperatures
            } else if (this.value >= 32) {
                return '#dc3545'; // Dark Red for extreme heat
            }
            return '#198754';
        }
    },
    methods: {
        chartData() {
            return {
                labels: ['Temperature (c)'],
                datasets: [{
                    data: this.dataSetData,
                    label: 'Temp (c)',
                    backgroundColor: [this.arcColor, "#2b3035"]
                }]
            }
        }
    },
    data() {
        return {
            range: 55,
            chartOptions: {
                rotation: 270,
                cutout: '60%',
                circumference: 180,
                responsive: true,
                aspectRatio:2,
                layout: {
                    autoPadding: false,
                    padding:0
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },

            },
        }
    }
}
</script>