export default class WebSocketService {
    constructor(url) {
      this.url = url;
      this.socket = null;
    }
  
    connect() {
      return new Promise((resolve, reject) => {
        if (!this.url) return reject('WebSocket URL not provided');
        this.socket = new WebSocket(this.url);
  
        this.socket.onopen = () => resolve();
        this.socket.onerror = (error) => reject(error);
      });
    }
  
    onMessage(callback) {
      if (!this.socket) throw new Error('WebSocket not connected');
      this.socket.onmessage = (message) => callback(message);
    }
  
    sendMessage(message) {
      if (!this.socket) throw new Error('WebSocket not connected');
      this.socket.send(message);
    }
  
    close() {
      if (this.socket) this.socket.close();
    }
  }