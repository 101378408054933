<template>
    <div class="card extraMetric">
        <div class="card-body p-2">
            <strong>{{value}}</strong>
            <span>{{title}}</span>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ExtraMetric',
    props: {
      title: String,
      value: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .extraMetric {
        display:flex;
        justify-content: center;
        align-items:center;
        text-align:center;
        height:100%;
    }
    .extraMetric .card-body {
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
    }
    .extraMetric strong {
        font-weight:bold;
    }
    .extraMetric span {
        text-transform:uppercase;
        font-weight:100;
        font-size:0.75rem;
        display:block;
        margin-top: 2px;
        color: #c8c8c8;
        letter-spacing: 0.3px;
    }
    @media(max-width:1400px) {
        .extraMetric span {
            letter-spacing:0.1px;
            font-size:0.6rem;
        }
    }
    @media(max-width:992px) {
        .extraMetric span {
            letter-spacing:0px;
            font-size:0.6rem;
        }
        .extraMetric strong {
            letter-spacing:0px;
            font-size:0.8rem;
        }
    }
  </style>
  