
<template>
    <div class="container ">
        <div class="d-flex justify-content-between">
            <h1 class="h4">Live Weather</h1>
            <h4 class="d-flex"><div class="d-flex justify-content-center align-items-center me-2"><i style="font-size:0.7rem;" class="bi bi-circle-fill text-success liveAnim"></i></div> {{ time }}</h4>
        </div>
        <div v-if="weatherData" class="row mt-4 gy-4 justify-content-start">
            <div class="col-6 col-md-4 col-xl-3">
                <TemperatureChart :value="weatherData.Outdoor.temp_c" />
                <div class="row gx-2">
                    <div class="col-4">
                        <ExtraMetric :title="'Dew Pt.'" :value="dewPoint + '&deg;C'" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Day Max'" :value="weatherData.Outdoor.day_temp_max_c + '&deg;C'" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Day Min'" :value="weatherData.Outdoor.day_temp_min_c + '&deg;C'" />
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
                <HumidityChart :value="weatherData.Outdoor.humidity_percent" />
                <div class="row gx-2">
                    <div class="col-4">
                        <ExtraMetric :title="'Humidex'" :value="humidex" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Day Max'" :value="weatherData.Outdoor.day_humidity_max_percent + '%'" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Day Min'" :value="weatherData.Outdoor.day_humidity_min_percent + '%'" />
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
                <WindChart :value="convertToMph(weatherData.Wind.speed_avg_ms)" />
                <div class="row gx-2">
                    <div class="col-6">
                        <ExtraMetric :title="'Gust'" :value="convertToMph(weatherData.Wind.speed_gust_ms) + 'mph'" />
                    </div>
                    <div class="col-6">
                        <ExtraMetric :title="'Day Max'" :value="convertToMph(weatherData.Wind.day_speed_max_ms) + 'mph'" />
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
                <PressureChart :value="weatherData.Pressure.relative_mb" />
                <div class="row gx-2">
                    <div class="col-6">
                        <ExtraMetric :title="'Day Min'" :value="weatherData.Pressure.day_relative_min_mb + 'mb'" />
                    </div>
                    <div class="col-6">
                        <ExtraMetric :title="'Day Max'" :value="weatherData.Pressure.day_relative_max_mb + 'mb'" />
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
                <RainRateChart :value="weatherData.Rain.rate_mmperhour" />
                <div class="row gx-2">
                    <div class="col-4">
                        <ExtraMetric :title="'Day'" :value="weatherData.Rain.daily_mm + 'mm'" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Week'" :value="weatherData.Rain.weekly_mm + 'mm'" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Month'" :value="weatherData.Rain.monthly_mm + 'mm'" />
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
                <LightningChart :value="weatherData.Lightning.daycount" />
                <div class="row gx-2">
                    <div class="col-6">
                        <ExtraMetric :title="'Distance'" :value="weatherData.Lightning.distance_miles + ' miles'" />
                    </div>
                    <div class="col-6">
                        <ExtraMetric :title="'Last'" :value="lightningTimeAgo" />
                    </div>
                </div>

            </div>
            <div class="col-6 col-md-4 col-xl-3">
                <SolarChart :value="weatherData.Solar.lux" />
                <div class="row gx-2">
                    <!--<div class="col-4">
                        <ExtraMetric :title="'Sunrise'" :value="'08:00'" />
                    </div>
                    <div class="col-4">
                        <ExtraMetric :title="'Sunset'" :value="'17:30'" />
                    </div>-->
                    <div class="col-4">
                        <ExtraMetric :title="'Est. UVI'" :value="weatherData.Solar.uv_index" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// @ is an alias to /src
import TemperatureChart from "@/components/TemperatureChart.vue"
import HumidityChart from "@/components/HumidityChart.vue"
import WindChart from "@/components/WindChart.vue"
import PressureChart from "@/components/PressureChart.vue"
import RainRateChart from "@/components/RainRateChart.vue"
import LightningChart from "@/components/LightningChart.vue"
import SolarChart from "@/components/SolarChart.vue"
import ExtraMetric from "@/components/ExtraMetric.vue"
import WebSocketService from '@/services/WebSocketService.js';

export default {
  name: 'HomeView',
  components: {
    TemperatureChart,
    HumidityChart,
    WindChart,
    PressureChart,
    RainRateChart,
    LightningChart,
    SolarChart,
    ExtraMetric
  },
  methods: {
    convertToMph(metersPerSeconds) {
      return (metersPerSeconds * (3600 / 1609.34)).toFixed(1);
    }
  },
  computed: {
    dewPoint() {
        return (this.weatherData.Outdoor.temp_c - ((100 - this.weatherData.Outdoor.humidity_percent) / 5)).toFixed(1);
    },
    humidex() {
      // Ensure that temperature and dewPoint are numbers and not undefined or null
      if (typeof parseFloat(this.weatherData.Outdoor.temp_c) !== 'number' || typeof parseFloat(this.dewPoint) !== 'number') {
        return 'Invalid input';
      }

      // Calculate e using the correct formula
      const e = 6.11 * Math.exp(5417.7530 * ((1 / 273.16) - (1 / (parseFloat(this.dewPoint) + 273.16))));
      
      // Check for NaN in e to ensure it's a valid number
      if (isNaN(e)) {
        return 'Calculation error';
      }

      const h = parseFloat(this.weatherData.Outdoor.temp_c) + 0.5555 * (e - 10);
      return h.toFixed(0);
    },
    lightningTimeAgo() {
      const now = Date.now();
      const diffInSeconds = Math.floor((now) / 1000) - this.weatherData.Lightning.datetime;

      if (diffInSeconds < 60) {
        return `${diffInSeconds} seconds ago`;
      } else if (diffInSeconds < 3600) {
        return `${Math.floor(diffInSeconds / 60)} minutes ago`;
      } else if (diffInSeconds < 86400) {
        return `${Math.floor(diffInSeconds / 3600)} hours ago`;
      } else {
        return `${Math.floor(diffInSeconds / 86400)} days ago`;
      }
    },
  },
  data() {
    return {
        time: Intl.DateTimeFormat(navigator.language, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }).format(),
        timeInterval: null,
        weatherData: null,
        webSocketService: new WebSocketService('wss://tuffleyweather.co.uk:3001')
    }
  },
  beforeUnmount() {
    clearInterval(this.timeInterval)
  },
  async mounted() {

    // update the time every second
    this.timeInterval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000);

    try {
        const response = await fetch('https://tuffleyweather.co.uk/api/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        this.weatherData = await response.json();

        this.webSocketService.connect()
        .then(() => {
          this.webSocketService.onMessage((message) => {
            this.weatherData = {...this.weatherData, ...JSON.parse(message.data)}
          });
          this.webSocketService.sendMessage('{"type": "subscribe","channel":"live"}');
        })
        .catch((error) => console.error('WebSocket error:', error));

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }

  }
}
</script>

<style scoped>

    .liveAnim {
        animation: fade-in-out 3s infinite;
    }

    @keyframes fade-in-out {

        from {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        to {
            opacity: 0;
        }

    }

</style>
