<template>
    <div class="chartContainer text-center">
        <h2 class="h3">Rain</h2>
        <Doughnut :options="chartOptions" :data="chartData()" />
        <h3 class="h4">{{ value }} mm/hr</h3>
    </div>
</template>
  
<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'RainRateChart',
    props: ['value'],
    components: { Doughnut },
    computed: {
        dataSetData() {
            let valueArc = this.value;
            let remainderArc = this.range - valueArc;
            return [(valueArc/this.range),(remainderArc/this.range)]
        },
        arcColor() {
            if (this.value === 0) {
                return '#e3f2fd'; // Lightest Blue for no rain
            } else if (this.value > 0 && this.value <= 2.5) {
                return '#0dcaf0'; // Light Blue for light rain
            } else if (this.value > 2.5 && this.value <= 5) {
                return '#0d6efd'; // Bootstrap Primary Blue for moderate rain
            } else if (this.value > 5 && this.value <= 10) {
                return '#0056b3'; // Darker Blue for heavy rain
            } else if (this.value > 10) {
                return '#004085'; // Darkest Blue for very heavy rain
            }
            return '#0d6efd'; // Default to Bootstrap Primary Blue if no specific condition is met
        }
    },
    methods: {
        chartData() {
            return {
                labels: ['Rain (mm/hr)'],
                datasets: [{
                    data: this.dataSetData,
                    label: 'Rain (mm/hr)',
                    backgroundColor: [this.arcColor, "#2b3035"]
                }]
            }
        }
    },
    data() {
        return {
            range: 20,
            chartOptions: {
                rotation: 270,
                cutout: '60%',
                circumference: 180,
                responsive: true,
                aspectRatio:2,
                layout: {
                    autoPadding: false,
                    padding:0
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },

            },
        }
    }
}
</script>